import { computed } from 'vue'
import { selectVersion, selectVersionByConfig } from '@/utils/abTesting'
import { ABTestConfig } from '@/composables/useABTest/types'

export default function (experimentId: string, config?: ABTestConfig) {
  // @ts-ignore
  const { $trackEvent } = useNuxtApp()
  // @ts-ignore
  const experimentVersion = useCookie<string>(experimentId)

  const isVersionSet = computed(() => !!experimentVersion.value)

  const initSelectVersion = () => {
    if (!isVersionSet.value) {
      if (config) {
        experimentVersion.value = selectVersionByConfig(config.versionProbabilities)
      } else {
        experimentVersion.value = selectVersion()
      }
    }
  }

  const trackAbTestEvent = () => {
    $trackEvent({
      name: 'experiment_viewed',
      version: '1-0-0',
      data: {
        experiment_id: experimentId,
        variation_id: experimentVersion.value
      }
    })
  }

  const initExperiment = () => {
    initSelectVersion()
    trackAbTestEvent()
  }

  return {
    experimentVersion,
    initExperiment,
    initSelectVersion,
    trackAbTestEvent,
    isVersionSet
  }
}
