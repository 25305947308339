<script setup lang="ts">
import RoomieButtonLink from '@lahaus-roomie/roomie/src/components/RoomieButtonLink/index.vue'
import type { ReferralBannerComponentModule } from '@/components/Referrals/Banner/types'

defineProps<ReferralBannerComponentModule.Props>()

defineEmits(['submit-form'])
</script>

<script lang="ts">
export default {
  name: 'ReferralBanner'
}
</script>

<template>
  <div class="flex flex-col gap-lh-24 items-start md:items-center md:flex-row p-lh-24 md:p-lh-32 justify-between rounded-[2rem] referral-banner">
    <div>
      <h2 class="text-24 text-white md:text-38">
        {{ title }} <span class="text-brand-600 font-semibold">{{ subtitle }}</span>
      </h2>

      <p class="text-14 text-white md:text-16 font-medium">
        {{ description }}
      </p>
    </div>

    <div class="w-full md:w-auto mt-lh-24 md:m-0">
      <RoomieButtonLink
        :id="`${id}-button-register-now`"
        :data-lh-id="`${id}-button-register-now`"
        class="w-full"
        target-type="_blank"
        :url="submitButtonUrl">
        {{ submitButtonText }}
      </RoomieButtonLink>
    </div>
  </div>
</template>

<style lang="scss">
.referral-banner {
  background: radial-gradient(42.71% 50.67% at 3.06% 4.43%, rgb(31 121 101 / 92%) 0%, rgb(21 24 24 / 92%) 100%);
}
</style>
